import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { deepCopy, notify } from "@/core/helpers/globalJaya";
import OfflineService from "@/core/services/OfflineService";
import { Indicator } from "@/store/modules/PrescriberModule";
import ApiService from "@/core/services/ApiService";
export interface Exploitation {
  id?: number;
  date_created: string;
  siret?: string;
  name?: string;
  legal_status?: number;
  location_address?: string;
  location_point?: string;
  exploitation_versions: Array<ExploitationVersion>;
}

export interface AdditionalIndicator {
  value: string;
  indicator: number;
  exploitation: number;
  indicator_details?: Indicator;
  id: number;
}

export interface ExploitationVersion {
  id?: number;
  exploitation?: number;
  date_created: string;
  biodiversity_progress?: Array<number> | null;
  biodiversity_performance?: number;
  usefull_area?: number;
  siqo?: Array<number> | null;
  pacage_number?: number;
  otex?: OTEX | null;
  otex_details: string;
  additional_indicators?: Array<AdditionalIndicator>;
}

export interface LegalStatus {
  id: number;
  name: string;
}

export interface OTEX {
  id: number | null;
  name?: string;
  id_ote64?: number;
  order?: number;
}

export interface SIQO {
  id: number | null;
  name?: string;
}

export interface BiodiversityProgress {
  id: number;
  name: string;
  label: string;
}

export interface CoopInfos {
  siret: string;
}

@Module
export default class ExploitationsModule extends VuexModule {
  exploitations = [] as Array<Exploitation>;
  legaStatus = [
    {
      id: 1,
      name: "EIRL",
    },
    {
      id: 2,
      name: "SCEA",
    },
    {
      id: 3,
      name: "GAEC",
    },
    {
      id: 4,
      name: "EARL",
    },
    {
      id: 5,
      name: "SEP",
    },
    {
      id: 6,
      name: "GFA",
    },
    {
      id: 7,
      name: "SA",
    },
    {
      id: 8,
      name: "SAS",
    },
  ] as Array<LegalStatus>;
  otex = [] as Array<OTEX>;
  siqo = [
    {
      id: 1,
      name: "AB",
    },
    {
      id: 2,
      name: "IGP",
    },
    {
      id: 3,
      name: "AOC-AOP",
    },
    {
      id: 4,
      name: "Label rouge",
    },
    {
      id: 5,
      name: "STG",
    },
    {
      id: 6,
      name: "Aucun",
    },
  ] as Array<SIQO>;
  biodiversityProgress = [
    {
      id: 1,
      name: "Formation (individuelle ou collective)",
      label: "Formation",
    },
    {
      id: 2,
      name: "Diagnostic",
      label: "Diagnostic",
    },
    {
      id: 3,
      name: "Observation & comptage (OAB, autres)",
      label: "Observation & comptage",
    },
    {
      id: 4,
      name: "Réseau d'agriculteurs.rices & groupes pilotes (GIEE, groupe 30 000, réseau AgriFaune, projet Ecophyto, groupes de progrés non-labellisés, etc.)",
      label: "Réseau",
    },
    {
      id: 5,
      name: "Mesures AgroEnvironnementales et Climatiques",
      label: "MAEC",
    },
    {
      id: 6,
      name: "Mise en place d'actions ciblées (agroforesterie, haies, prairies, couverts mélifères, etc.)",
      label: "Actions ciblées",
    },
    {
      id: 7,
      name: "Certifications, Labels et marques collectives (HVE, Agriconfiance, vignerons engagés, Bee Friendly, Demeter, marque Parc, label Haies, etc.)",
      label: "Certifications",
    },
    {
      id: 8,
      name: "Référentiels interprofessionnels (charte de bonnes pratiques d'élevage, etc.)",
      label: "Référentiels",
    },
    {
      id: 9,
      name: "Marques & cahier des charges privées",
      label: "Marques privées",
    },
    {
      id: 10,
      name: "Aucun",
      label: "Aucun",
    },
  ] as Array<BiodiversityProgress>;

  mandatoryFieldToUpdate = false;

  get getExploitations() {
    return this.exploitations;
  }

  get getLegalStatus() {
    return this.legaStatus;
  }

  get getOTEX() {
    return this.otex;
  }

  get getSIQO() {
    return this.siqo;
  }

  get getBiodiversityProgress() {
    return this.biodiversityProgress;
  }

  get getmandatoryFieldToUpdate() {
    return this.mandatoryFieldToUpdate;
  }

  @Mutation
  [Mutations.SET_OTEX](payload) {
    const new_otex = payload;
    this.otex.push(new_otex);
  }

  @Mutation
  [Mutations.SET_EXPLOITATION](payload) {
    const new_exploitation = deepCopy(payload);
    new_exploitation.exploitation_versions = [];
    if (!new_exploitation.legal_status) {
      new_exploitation.legal_status = -1;
    }
    this.exploitations.push(new_exploitation);
  }

  @Mutation
  [Mutations.UPDATE_SET_EXPLOITATION](payload) {
    const exploitationIndex = this.exploitations.findIndex(
      (e) => e.id === payload.exploitation.id
    );
    const oldExploitation = this.exploitations[exploitationIndex];
    if (oldExploitation) {
      const newExploitation = {
        id: oldExploitation.id,
        name: oldExploitation.name,
      } as Exploitation;
      if (payload.exploitation.name) {
        newExploitation.name = payload.exploitation.name;
      }
      if (payload.exploitation.siret) {
        newExploitation.siret = payload.exploitation.siret;
      }
      if (payload.exploitation.legal_status) {
        newExploitation.legal_status = payload.exploitation.legal_status;
      }
      if (payload.exploitation.location_address) {
        newExploitation.location_address =
          payload.exploitation.location_address;
      }
      if (payload.exploitation.location_point) {
        newExploitation.location_point = payload.exploitation.location_point;
      }
      newExploitation.exploitation_versions = deepCopy(
        oldExploitation.exploitation_versions
      );
      const versionIndex = newExploitation.exploitation_versions.findIndex(
        (e) => e.id === payload.version.id
      );
      const versionUpdate = {
        id: oldExploitation.exploitation_versions[versionIndex].id,
        date_created:
          oldExploitation.exploitation_versions[versionIndex].date_created,
      } as ExploitationVersion;
      if (payload.version.biodiversity_progress) {
        versionUpdate.biodiversity_progress =
          payload.version.biodiversity_progress;
      }
      if (payload.version.biodiversity_performance) {
        versionUpdate.biodiversity_performance =
          payload.version.biodiversity_performance;
      }
      if (payload.version.usefull_area) {
        versionUpdate.usefull_area = payload.version.usefull_area;
      }
      if (payload.version.siqo) {
        versionUpdate.siqo = payload.version.siqo;
      }
      if (payload.version.pacage_number) {
        versionUpdate.pacage_number = payload.version.pacage_number;
      }
      if (payload.version.otex) {
        versionUpdate.otex = payload.version.otex;
      }
      newExploitation.exploitation_versions[versionIndex] = versionUpdate;
      this.exploitations[exploitationIndex] = newExploitation;
    } else {
      notify({
        text: "Exploitation non trouvée",
        color: "error",
        duration: 3000,
      });
    }
  }

  @Mutation
  [Mutations.REMOVE_EXPLOITATION](payload) {
    const exploitationIndex = this.exploitations.findIndex(
      (e) => e.id === payload.id
    );
    this.exploitations.splice(exploitationIndex, 1);
  }

  @Mutation
  [Mutations.SET_EXPLOITATION_VERSION](payload) {
    const newVersion = {
      id: payload.id,
      date_created: payload.date_created,
    } as ExploitationVersion;
    const exploitation = this.exploitations.find(
      (exploitation) => exploitation.id === payload.exploitation
    );
    if (exploitation) {
      if (payload.biodiversity_progress) {
        newVersion.biodiversity_progress = payload.biodiversity_progress;
      }
      if (payload.biodiversity_performance) {
        newVersion.biodiversity_performance = payload.biodiversity_performance;
      }
      if (payload.usefull_area) {
        newVersion.usefull_area = payload.usefull_area;
      }
      if (payload.siqo) {
        newVersion.siqo = payload.siqo;
      }
      if (payload.pacage_number) {
        newVersion.pacage_number = payload.pacage_number;
      }
      if (payload.otex) {
        newVersion.otex = payload.otex;
      } else {
        newVersion.otex = { id: -1 };
      }
      if (payload.otex_details) {
        newVersion.otex_details = payload.otex_details;
      } else {
        newVersion.otex_details = "";
      }
      if (payload.additional_indicators) {
        newVersion.additional_indicators = payload.additional_indicators;
      } else {
        newVersion.additional_indicators = [];
      }
      exploitation.exploitation_versions.push(newVersion);
    }
  }
  @Mutation
  [Mutations.SET_MANDATORY_FIELD_TO_UPDATE](payload) {
    this.mandatoryFieldToUpdate = payload;
  }

  @Action
  [Actions.FETCH_OTEX]() {
    return new Promise((resolve, reject) => {
      OfflineService.fetchObject("otex")
        .then((response) => {
          this.otex.splice(0, this.otex.length);
          for (const otex of response) {
            this.context.commit(Mutations.SET_OTEX, otex);
          }
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de récupérer les OTEX";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.FETCH_EXPLOITATIONS]() {
    return new Promise((resolve, reject) => {
      OfflineService.fetchObject("exploitations")
        .then((response) => {
          this.exploitations.splice(0, this.exploitations.length);
          for (const exploitation of response) {
            this.context.commit(Mutations.SET_EXPLOITATION, {
              id: exploitation.id,
              legal_status: exploitation.legal_status,
              name: exploitation.name,
              location_point: exploitation.location_point,
              location_address: exploitation.location_address,
              siret: exploitation.siret,
            });

            exploitation.exploitation_versions
              .sort((a, b) =>
                new Date(a.date_created).getTime() >
                new Date(b.date_created).getTime()
                  ? -1
                  : 1
              )
              .forEach((ev) => {
                this.context.commit(Mutations.SET_EXPLOITATION_VERSION, ev);
              });
          }
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de récupérer les exploitations";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_EXPLOITATION](payload) {
    return new Promise((resolve, reject) => {
      // remove versions
      payload.exploitation.exploitation_versions = [];
      // todo make sure it works for version
      OfflineService.addObject("exploitations", payload.exploitation)
        .then((exploitationResponse) => {
          payload.version.exploitation = exploitationResponse.id;
          payload.version.newExploiation = exploitationResponse;
          this.context
            .dispatch(Actions.ADD_EXPLOITATION_VERSION, payload.version)
            .then(() => {
              notify({
                text: "Exploitation créée",
                color: "success",
                duration: 3000,
              });
              resolve(
                this.exploitations.find((e) => e.id === exploitationResponse.id)
              );
            });
        })
        .catch((error) => {
          const err_msg = "Impossible d'ajouter l'exploitation";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_EXPLOITATION](payload) {
    return new Promise((resolve, reject) => {
      OfflineService.updateObject("exploitations", payload.exploitation)
        .then((exploitationResponse) => {
          payload.version.exploitation = exploitationResponse.id;
          this.context
            .dispatch(Actions.UPDATE_EXPLOITATION_VERSION, payload.version)
            .then(() => {
              this.context.commit(Mutations.UPDATE_SET_EXPLOITATION, {
                exploitation: exploitationResponse,
                version: payload.version,
              });
              notify({
                text: "Exploitation mise à jour",
                color: "success",
                duration: 3000,
              });
            });
          resolve(exploitationResponse);
        })
        .catch((error) => {
          const err_msg = "Impossible de mettre à jour l'exploitation";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.DELETE_EXPLOITATION](payload) {
    return new Promise((resolve, reject) => {
      OfflineService.deleteObject("exploitations", payload)
        .then((response) => {
          this.context.commit(Mutations.REMOVE_EXPLOITATION, payload);
          notify({
            text: "Exploitation supprimée",
            color: "success",
            duration: 3000,
          });
          resolve(response);
        })
        .catch((error) => {
          const err_msg = "Impossible de supprimer l'exploitation";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_EXPLOITATION_VERSION](payload) {
    const linkedExploitation = payload.newExploiation
      ? payload.newExploiation
      : null;
    //delete object as to not have infinate cycle in object
    delete payload.newExploiation;
    return new Promise((resolve, reject) => {
      OfflineService.addObject("exploitation_versions", payload)
        .then((result) => {
          if (linkedExploitation) {
            this.context.commit(Mutations.SET_EXPLOITATION, linkedExploitation);
          }
          this.context.commit(Mutations.SET_EXPLOITATION_VERSION, result);
          resolve(result);
        })
        .catch((error) => {
          if (linkedExploitation) {
            this.context.dispatch(Actions.DELETE_EXPLOITATION, {
              id: linkedExploitation.id,
            });
          }
          const err_msg = "Impossible d'ajouter la version d'exploitation";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.ADD_INDICATOR_VALUE](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("exploitation_indicators/", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.UPDATE_INDICATOR_VALUE](payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch(`exploitation_indicators/${payload.id}/  `, payload)
        .then((response) => {
          resolve(response);
        })
        .catch((e) => reject(e));
    });
  }

  @Action
  [Actions.UPDATE_EXPLOITATION_VERSION](payload) {
    return new Promise((resolve, reject) => {
      OfflineService.updateObject("exploitation_versions", payload)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          const err_msg =
            "Impossible de mettre à jour la version d'exploitation";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_COOP_INFOS]() {
    return new Promise((resolve, reject) => {
      ApiService.get("coop_infos")
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => reject(e));
    });
  }
}

import {
  createRouter,
  createWebHistory,
  LocationQueryValue,
  RouteRecordRaw,
} from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import { notify } from "@/core/helpers/globalJaya";
import { computed } from "vue";
import OfflineService from "@/core/services/OfflineService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { CookieComponent } from "@/assets/ts/components";

const routes: Array<RouteRecordRaw> = [
  { path: "/index.html", redirect: "/accueil" },
  {
    path: "",
    redirect: "/accueil",
    component: () => import("@/layout/Layout.vue"),
    meta: {
      mustBeOnline: false,
      mustBeAuth: true,
      displayPrev: true,
    },
    children: [
      {
        path: "/accueil",
        name: "homepage",
        component: () => import("@/views/HomePage.vue"),
        meta: {
          mustBeOnline: false,
          mustBeAuth: true,
          displayPrev: false,
        },
      },
      {
        path: "/compte-utilisateur",
        name: "useraccount",
        component: () => import("@/views/accounts/UserAccount.vue"),
        meta: {
          mustBeOnline: true,
        },
      },
      {
        path: "/diagnostics",
        name: "diagnostic-list",
        component: () => import("@/views/diagnostic/Diagnostics.vue"),
      },
      {
        path: "/nouveau-diagnostic",
        name: "new-diagnostic",
        component: () => import("@/views/diagnostic/NewDiagnostic.vue"),
      },
      {
        path: "/diagnostic/:diagnosticId",
        name: "edit-diagnostic",
        component: () => import("@/views/diagnostic/EditDiagnostic.vue"),
      },
      {
        path: "/resultats/:diagnosticId",
        name: "diagnostic-results",
        component: () => import("@/views/diagnostic/DiagnosticResults.vue"),
      },
      {
        path: "/evolution-resultats/:exploitationId",
        name: "diagnostic-evolution-results",
        component: () =>
          import("@/views/diagnostic/DiagnosticResultsEvolution.vue"),
      },
      {
        path: "/comparaison-exploitation",
        name: "exploitation-evolution-results",
        component: () =>
          import("@/views/diagnostic/ExploitationCompareResults.vue"),
      },
      {
        path: "/comparaison-resultats/:diagnosticId",
        name: "diagnostic-compare-results",
        component: () =>
          import("@/views/diagnostic/DiagnosticCompareResults.vue"),
      },
      {
        path: "/services-ecosystemiques/:categoryId/diagnostic/:diagnosticId",
        name: "ecosystemic-services",
        component: () =>
          import("@/views/diagnostic/DiagnosticEcosystemicServices.vue"),
        props: true,
      },
      {
        path: "/nouvelle-exploitation",
        name: "new-exploitation",
        component: () => import("@/views/exploitations/NewExploitations.vue"),
      },
      {
        path: "/exploitations",
        name: "exploitations",
        component: () => import("@/views/exploitations/Exploitations.vue"),
      },
      {
        path: "/partenaires",
        name: "partners",
        component: () => import("@/views/details/Partners.vue"),
      },
      {
        path: "/aller-plus-loin",
        name: "go-further",
        component: () => import("@/views/details/GoFurther.vue"),
      },
      {
        path: "/signature-rgpd",
        name: "gdpr-signature",
        component: () => import("@/views/GdprSignature.vue"),
        meta: {
          mustBeOnline: true,
        },
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/notifications/Notifications.vue"),
        meta: {
          mustBeOnline: true,
        },
      },
      {
        path: "/liste-services-ecosystemiques/:serviceId?",
        name: "ecosystemic-services-list",
        component: () => import("@/views/diagnostic/EcosystemicServices.vue"),
        props: true,
      },
      {
        path: "/methode-calcul-services-ecosystemiques",
        name: "ecosystemic-services-computing-method",
        component: () =>
          import("@/views/diagnostic/ComputingMethodEcosystemicServices.vue"),
        props: true,
      },
      {
        path: "/agribest",
        name: "what-is-agribest",
        component: () => import("@/views/details/WhatIsAgriBEST.vue"),
        props: true,
      },
      {
        path: "/mes-projets",
        name: "my-projects",
        component: () => import("@/views/prescribers/ProjectUserList.vue"),
        meta: { mustBeOnline: true },
        props: true,
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    meta: {
      mustBeOnline: true,
      mustBeAuth: false,
      displayPrev: true,
    },
    children: [
      {
        path: "/connexion",
        name: "sign-in",
        component: () => import("@/views/accounts/SignIn.vue"),
      },
      {
        path: "/inscription",
        name: "sign-up",
        component: () => import("@/views/accounts/SignUp.vue"),
      },
      {
        path: "/mot-de-passe-oublie",
        name: "password-forgot",
        component: () => import("@/views/accounts/PasswordForgot.vue"),
      },
      {
        path: "/mot-de-passe-reinitialisation",
        name: "password-reset",
        component: () => import("@/views/accounts/PasswordReset.vue"),
      },
      {
        path: "/verification-utilisateur",
        name: "verify-user",
        component: () => import("@/views/accounts/VerifyUser.vue"),
      },
      {
        path: "/mentions-legales",
        name: "legal-notice",
        component: () => import("@/views/GdprDocViewer.vue"),
      },
      {
        path: "/conditions-utilisation",
        name: "general-conditions",
        component: () => import("@/views/GdprDocViewer.vue"),
      },
      {
        path: "/charte-protection-donnees",
        name: "gdpr-charter",
        component: () => import("@/views/GdprDocViewer.vue"),
      },
      {
        path: "/politique-cookies",
        name: "cookie-policy",
        component: () => import("@/views/GdprDocViewer.vue"),
      },
      {
        path: "/charte-prescripteurs",
        name: "prescriber-charter",
        component: () => import("@/views/GdprDocViewer.vue"),
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import("@/views/Contact.vue"),
      },
      {
        path: "/mise-a-jour-requise",
        name: "update-required",
        component: () => import("@/views/versioning/UpdateRequired.vue"),
      },
      {
        path: "/coop-auth",
        name: "coop-auth",
        component: () => import("@/views/accounts/CoopAuth.vue"),
      },
      {
        path: "/coop-login",
        name: "coop-login",
        component: () => import("@/views/accounts/CoopLogin.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/BackOfficeLayout.vue"),
    meta: {
      mustBeOnline: true,
      mustBeAuth: true,
      mustBeFounder: true,
    },
    children: [
      {
        path: "/backoffice",
        name: "founder-backoffice",
        component: () => import("@/views/backoffice/BackOfficeCF.vue"),
      },
      {
        path: "/nouveau-prescripteur",
        name: "new-prescriber",
        component: () => import("@/views/prescribers/NewPrescriber.vue"),
      },
      {
        path: "/prescripteurs/:prescriberId/:mode",
        name: "prescriber",
        component: () => import("@/views/prescribers/Prescriber.vue"),
      },
      {
        path: "/prescripteurs/visualisation-projet/:projectId",
        name: "display-project-founders",
        component: () =>
          import("@/views/prescribers/DisplayProjectFounders.vue"),
        props: true,
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/BackOfficeLayout.vue"),
    meta: {
      mustBeOnline: true,
      mustBeAuth: true,
      mustBePrescriber: true,
    },
    children: [
      {
        path: "/backoffice-prescripteur",
        name: "prescriber-backoffice",
        component: () => import("@/views/prescribers/PrescriberBackOffice.vue"),
      },
      {
        path: "/backoffice-prescripteur/nouveau-projet",
        name: "new-project",
        component: () => import("@/views/prescribers/CreateProject.vue"),
      },
      {
        path: "/backoffice-prescripteur/maj-projet/:projectId",
        name: "update-project",
        component: () => import("@/views/prescribers/UpdateProject.vue"),
        props: true,
      },
      {
        path: "/backoffice-prescripteur/visualisation-projet/:projectId",
        name: "display-project",
        component: () => import("@/views/prescribers/DisplayProject.vue"),
        props: true,
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/Error404.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];
declare let window: any;
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from) => {
  let projectId: string | LocationQueryValue[] | undefined = undefined;
  if (from.query && from.query.projectId) {
    if (!to.query.projectId && to.name) {
      projectId = from.query.projectId;
    }
  }
  // Check online status
  const isOnline = computed(() => store.getters.isOnline);
  if (!from.name) {
    to.meta.displayPrev = false;
  }
  if (isOnline.value) {
    const csrfToken = CookieComponent.get("csrftoken") ?? "";
    CookieComponent.set("csrftoken", csrfToken, { expires: new Date() });
    await store.dispatch(Actions.VERIFY_AUTH);
    if (store.getters.isUserAuthenticated) {
      // Check GDPR doc signature
      await store.dispatch(Actions.FETCH_GDPR_LAST_DOCS_NOT_SIGNED, {
        user: store.getters.currentUser.id,
      });
      const previousExploitationsToUpdate =
        store.getters.getExploitationsToUpdate;
      await store.dispatch(Actions.CHECK_INDICATORS);

      // Check cookie
      const cookie_date = new Date(<string>localStorage.getItem("cookie_date"));
      const delta_t_cookie = new Date().getTime() - cookie_date.getTime();
      // has seen cookie message in last 6 month ?
      const isIos =
        window.cordova !== undefined && window.cordova.platformId !== "ios";
      if (delta_t_cookie > 1000 * 3600 * 24 * 30 * 6 && !isIos) {
        Swal.fire({
          title:
            "La plateforme AgriBEST n'utilise que des cookies indispensables",
          icon: "info",
          html:
            "Ils permettent notamment de vous identifier pour accéder à votre compte et de limiter la vulnérabilité" +
            " des systèmes d'authentification web.",
          confirmButtonText: "J'ai compris",
          showCancelButton: true,
          cancelButtonText: "En savoir plus",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-light-secondary",
          },
        }).then((result) => {
          const today = new Date();
          localStorage.setItem("cookie_date", today.toString());
          if (result.dismiss && result.dismiss === "cancel") {
            router.push({ name: "cookie-policy" });
          }
        });
      }

      // Force GDPR last docs signature
      if (
        store.getters.isUserAuthenticated &&
        store.getters.getGdprLastDocsNotSigned.length > 0 &&
        to.name !== "gdpr-signature"
      ) {
        notify({
          text: "Une nouvelle version des documents RGPD est disponible. Vous devez les accepter pour continuer.",
          color: "warning",
          duration: 3000,
        });
        if (projectId) {
          return {
            name: "gdpr-signature",
            query: { ...to.query },
          };
        } else {
          return {
            name: "gdpr-signature",
            query: { ...to.query },
          };
        }
      }

      if (
        store.getters.isUserAuthenticated &&
        previousExploitationsToUpdate.length === 0 &&
        store.getters.getExploitationsToUpdate.length > 0
      ) {
        router.push({ name: "exploitations" });
      }

      // If reload
      if (from.path === "/") {
        OfflineService.syncCache();
        // Workaround for Ecosystemic Service category undfined at diag results SE: TODO
        store.dispatch(Actions.FETCH_ECOSYSTEMIC_SERVICES_CATEGORIES);
        store.dispatch(Actions.FETCH_FACTOR_GROUPS);
      }

      // Fetch notif
      store.dispatch(Actions.FETCH_NOTIFICATIONS, {});
    } else {
      if (to.meta.mustBeAuth) {
        notify({
          text:
            "Il faut être authentifié pour accéder à : " +
            to.path.replace("/", " "),
          color: "warning",
          duration: 3000,
        });
        if (projectId) {
          return {
            name: "sign-in",
            query: { ...to.query, projectId: projectId },
          };
        }
        return { name: "sign-in", query: { ...to.query } };
      }
    }
  } else {
    if (to.meta.mustBeOnline) {
      notify({
        text:
          "Une connexion internet est demandée pour accéder à : " +
          to.path.replace("/", " "),
        color: "warning",
        duration: 3000,
      });
      return { name: "homepage" };
    }
  }

  // Check backoffice access
  if (to.meta.mustBeFounder && !store.getters.userHasFounderBackofficeAccess) {
    notify({
      text: "Vous n'avez pas la permission d'accéder à cette page",
      color: "warning",
      duration: 3000,
    });
    return { name: "homepage" };
  }

  if (
    to.meta.mustBePrescriber &&
    !store.getters.userHasPrescriberBackofficeAccess
  ) {
    notify({
      text: "Vous n'avez pas la permission d'accéder à cette page",
      color: "warning",
      duration: 3000,
    });
    return { name: "homepage" };
  }

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
  if (from.query && from.query.projectId) {
    if (!to.query.projectId && to.name && to.query.projectId !== "") {
      // add it to the next route
      return {
        path: to.path,
        query: { ...to.query, projectId: from.query.projectId },
      };
    } else {
      return;
    }
  } else {
    return;
  }
});

export default router;

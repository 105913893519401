enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  GET_PROFILE = "getProfile",
  LOGOUT = "logout",
  BASIC_LOGOUT = "basicLogout",
  FIREBASE_LOGOUT = "firebaseLogout",
  REGISTER = "register",
  RESEND_VALIDATION_EMAIL = "resendValidationEmail",
  VERIFY_REGISTRATION = "verifyRegistration",
  VERIFY_USER_STATUS = "verifyUserStatus",
  UPDATE_PROFILE = "updateProfile",
  FORGOT_PASSWORD = "forgotPassword",
  RESET_PASSWORD = "resetPassword",
  CHANGE_PASSWORD = "changePassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  DELETE_ACCOUNT = "deleteAccount",
  FETCH_EXPLOITATIONS = "fetchExploitations",
  ADD_EXPLOITATION = "addExploitation",
  UPDATE_EXPLOITATION = "updateExploitation",
  DELETE_EXPLOITATION = "deleteExploitation",
  ADD_EXPLOITATION_VERSION = "addExploitationVersion",
  UPDATE_EXPLOITATION_VERSION = "updateExploitationVersion",
  FETCH_OTEX = "fetchOtex",
  FETCH_QUIZ = "fetchQuiz",
  FETCH_DIAGNOSTICS = "fetchDiagnotics",
  ADD_DIAGNOSTIC = "addDiagnostic",
  UPDATE_DIAGNOSTIC = "updateDiagnostic",
  DELETE_DIAGNOSTIC = "deleteDiagnostic",
  ADD_DIAGNOSTIC_RESULT = "addDiagnosticResult",
  UPDATE_DIAGNOSTIC_RESULT = "updateDiagnosticResult",
  GET_DIAGNOSTIC_STATS = "getDiagnosticStats",
  FETCH_GDPR_LAST_DOCS = "fetchGdprLastDocs",
  GET_DIAGNOSTIC_STATS_FOUNDERS = "getDiagnosticStatsFounders",
  GET_DIAGNOSTIC_STATS_USER = "getDiagnosticStatsUser",
  SEND_MAIL_DB_DOWNLOAD_CSV = "sendMailDbDownloadCsv",
  SEND_MAIL_DB_DOWNLOAD_CSV_PRESCRIBERS = "sendMailDbDownloadCsvPrescribers",
  GET_LOCATIONS = "getLocations",
  CREATE_GDPR_SIGNATURE = "createGdprSignature",
  FETCH_GDPR_LAST_DOCS_NOT_SIGNED = "verifyUserGdprSignature",
  FETCH_GDPR_PURPOSE_STATE_GLOBAL = "fetchGdprPurposeStateGlobal",
  UPDATE_GDPR_PURPOSE_STATE_GLOBAL = "updateGdprPurposeStateGlobal",
  FETCH_AND_MANAGE_DEVICES = "fetchAndManageDevices",
  UPDATE_DEVICE = "updateDevice",
  FETCH_NOTIFICATIONS = "fetchNotifications",
  UPDATE_NOTIFICATION = "updateNotifications",
  FETCH_LATEST_NOTIFICATION = "fetchLatestNotification",
  REQUEST_FIREBASE_TOKEN = "requestFirebaseToken",
  FETCH_ECOSYSTEMIC_SERVICES_CATEGORIES = "fetchEcosystemicServicesCategories",
  DOWNLOAD_VIDEO = "downloadVideo",
  STOP_DOWNLOAD_VIDEO = "stopdownloadVideo",
  SAVE_VIDEO = "saveVideo",
  FETCH_FACTOR_GROUPS = "fetchFactorGroups",
  FETCH_MEAN_ECOSYSTEMIC_SERVICES = "fetchMeanEcosystemicServices",
  FETCH_MODULE_VIDEOS = "fetchModuleVideos",
  FETCH_MINIMUM_VERSION = "fetchMinimumVersion",
  FETCH_PRESCRIBER_FOUNDERS = "fetchPrescriberFounders",
  FETCH_PRESCRIBER_PROJECT_FOUNDERS = "fetchPrescriberProjectFounders",
  ADD_PRESCRIBER_FOUNDERS = "addPrescriberFounders",
  UPDATE_PRESCRIBER_FOUNDERS = "updatePrescriberFounders",
  DELETE_PRESCRIBER_FOUNDERS = "deletePrescriberFounders",
  UPDATE_PRESCRIBER_PROJECT_FOUNDER = "updatePrescriberProjectFounder",
  GET_PROJECT_BY_UUID = "getProjectByUuid",
  ADD_USER_PROJECT = "addUserProject",
  FETCH_PRESCRIBER_PROJECTS = "fetchPrescriberProjects",
  ADD_PRESCRIBER_PROJECT = "addPrescriberProject",
  UPDATE_PRESCRIBER_PROJECT = "updatePrescriberProject",
  GET_PRESCRIBER_PROJECT = "getPresciberProject",
  GET_PRESCRIBER_PROJECT_FOUNDERS = "getPresciberProjectFounders",
  DELETE_PRESCRIBER_PROJECT = "deletePrescriberProject",
  DELETE_PRESCRIBER_PROJECT_FOUNDERS = "deletePrescriberProjectFounders",
  FETCH_USER_PROJECTS = "fetchUserProjects",
  DELETE_USER_PROJECT = "deleteUserProject",
  GET_GENERAL_STATS = "getGeneralStats",
  FETCH_INDICATORS = "fetchIndicators",
  ADD_SELECTED_INDICATOR = "addSelectedIndicator",
  DESTROY_SELECTED_INDICATOR = "destroySelectedIndicator",
  ADD_INDICATOR_VALUE = "addIndicatorValue",
  UPDATE_INDICATOR_VALUE = "updateIndicatorValue",
  CHECK_INDICATORS = "checkIndicators",
  COOP_LOGIN = "coopLogin",
  GET_COOP_INFOS = "getCoopInfoS",
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_ONLINE = "setOnline",
  SET_ERROR = "setError",
  RESET_ERRORS = "resetErrors",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_EXPLOITATION = "setExploitation",
  REMOVE_EXPLOITATION = "removeExploitation",
  UPDATE_SET_EXPLOITATION = "updateSetExploitation",
  SET_EXPLOITATION_VERSION = "setExploitationVersion",
  SET_MANDATORY_FIELD_TO_UPDATE = "setmandatoryFieldToUpdate",
  SET_OTEX = "setOtex",
  SET_FACTOR = "setFactor",
  SET_DIAGNOSTIC = "setDiagnostic",
  EDIT_DIAGNOSTIC = "editDiagnostic",
  REMOVE_DIAGNOSTIC = "removeDiagnostic",
  SET_DIAGNOSTIC_RESULT = "setDiagnosticResult",
  REMOVE_DIAGNOSTIC_RESULT = "removeDiagnosticResult",
  SET_GDPR_LAST_DOCS = "setGdprLastDocs",
  SET_GDPR_LAST_DOCS_NOT_SIGNED = "setGdprLastDocsNotSigned",
  SET_GDPR_PURPOSE_STATE_GLOBAL = "setGdprPurposeStateGlobal",
  SET_FIREBASE_TOKEN_ID = "setFirebaseTokenId",
  SET_FIREBASE_TOKEN_VALUE = "setFirebaseTokenValue",
  SET_NOTIFICATION_COUNT = "setNotificationCount",
  SET_NOTIFICATION_COUNT_SENT = "setNotificationCountSent",
  APPEND_NOTIFICATIONS_END = "appNotificationsEnd",
  APPEND_NOTIFICATIONS_START = "appendNotificationsStart",
  UPDATE_OFFSET = "updateOffset",
  UPDATE_LIMIT = "updateLimit",
  UPDATE_SEEN_PARAM = "updateSeenParam",
  SET_ECOSYSTEMIC_SERVICES_CATEGORIES = "setEcosystemicServicesCategories",
  APPEND_VIDEO = "appendVideos",
  UPDATE_VIDEO_PROGRESS = "updateVideoProgess",
  REMOVE_VIDEO = "removeVideo",
  SET_FACTOR_GROUPS = "setFactorGroups",
  SET_PRESCRIBERS = "setPrescribers",
  REMOVE_PRESCRIBER = "removePrescriber",
  SET_PRESCRIBER_PROJECTS = "setPrescriberProjects",
  SET_MODULE_VIDEOS = "setModuleVideos",
  APPEND_PRESCRIBER_PROJECT = "appendPrescriberProject",
  REMOVE_PRESCRIBER_PROJECT = "removePrescriberProject",
  SET_USER_PROJECTS = "setUserProject",
  REMOVE_USER_PROJECT = "removeUserProject",
  SET_INDICATORS = "setIndicators",
  SET_EXPLOITATIONS_TO_UPDATE = "setExploitationsToUpdate",
}

export { Actions, Mutations };

import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import { notify } from "@/core/helpers/globalJaya";
import OfflineService from "@/core/services/OfflineService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import router from "@/router/router";

declare let window: any;

const manageLogout = async (payload, context) => {
  const response = await context.dispatch(Actions.BASIC_LOGOUT, payload);
  if (response) {
    context.commit(Mutations.SET_FIREBASE_TOKEN_VALUE, null);
    context.commit(Mutations.SET_FIREBASE_TOKEN_ID, null);
  }
  return response;
};

const parsePasswordStrengthError = (psw_str_err) => {
  if (psw_str_err === "The password is too similar to the username.") {
    return "Le mot de passe est trop similaire à l'email.";
  }
  if (psw_str_err === "The password is too similar to the first name.") {
    return "Le mot de passe est trop similaire à votre prénom.";
  }
  if (psw_str_err === "The password is too similar to the last name.") {
    return "Le mot de passe est trop similaire à votre nom de famille.";
  }
  if (
    psw_str_err ===
    "This password is too short. It must contain at least 10 characters."
  ) {
    return "Ce mot de passe est trop court. Il doit contenir au moins 10 caractères.";
  }
  if (psw_str_err === "This password is too common.") {
    return "Ce mot de passe est trop commun.";
  }
  if (psw_str_err === "This password must contain at least 1 digit.") {
    return "Ce mot de passe doit contenir au moins 1 chiffre.";
  }
  if (
    psw_str_err === "This password must contain at least 1 special character."
  ) {
    return "Ce mot de passe doit contenir au moins 1 caractère spécial.";
  }
  if (
    psw_str_err === "This password must contain at least 1 upper case letter."
  ) {
    return "Ce mot de passe doit contenir au moins 1 majuscule.";
  }
  if (
    psw_str_err === "This password must contain at least 1 lower case letter."
  ) {
    return "Ce mot de passe doit contenir au moins 1 minuscule.";
  }
  return "";
};

export interface User {
  id: number;
  is_superuser: boolean;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  professional_farmer: boolean;
  share_results: string;
  is_active: boolean;
  date_joined: string;
  is_founder: boolean;
  prescriber_id: number | null;
}

export interface UserAuthInfo {
  error: unknown;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  error = [];
  user = {} as User;
  isAuthenticated = false;
  online = navigator.onLine;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  get userHasFounderBackofficeAccess(): boolean {
    return this.currentUser.is_founder || this.currentUser.is_superuser;
  }

  get userHasPrescriberBackofficeAccess(): boolean {
    return (
      this.currentUser.prescriber_id !== null &&
      this.currentUser.prescriber_id > 0
    );
  }

  get isOnline(): boolean {
    return this.online;
  }

  @Mutation
  [Mutations.SET_ONLINE](online: boolean) {
    this.online = online;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.error = error[0];
    notify({
      text: this.error,
      color: "error",
    });
  }

  @Mutation
  [Mutations.RESET_ERRORS]() {
    this.error = [];
  }

  @Mutation
  [Mutations.SET_AUTH]() {
    this.isAuthenticated = true;
  }

  @Mutation
  [Mutations.SET_USER](user) {
    if (user.id !== undefined) {
      this.user.id = user.id;
    }

    if (user.is_superuser !== undefined) {
      this.user.is_superuser = user.is_superuser;
    }

    if (user.username !== undefined) {
      this.user.username = user.username;
    }

    if (user.first_name !== undefined) {
      this.user.first_name = user.first_name;
    }

    if (user.last_name !== undefined) {
      this.user.last_name = user.last_name;
    }

    if (user.email !== undefined) {
      this.user.email = user.email;
    }

    if (user.professional_farmer !== undefined) {
      this.user.professional_farmer = user.professional_farmer;
    }

    if (user.share_results !== undefined) {
      this.user.share_results = user.share_results;
    }

    if (user.is_active !== undefined) {
      this.user.is_active = user.is_active;
    }

    if (user.date_joined !== undefined) {
      this.user.date_joined = user.date_joined;
    }

    if (user.is_founder !== undefined) {
      this.user.is_founder = user.is_founder;
    }

    if (user.prescriber_id !== undefined) {
      this.user.prescriber_id = user.prescriber_id;
    }
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.error = [];
    if (process.env.VUE_APP_USE_TOKEN) {
      localStorage.removeItem("jayauthToken");
    }
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise((resolve, reject) => {
      this.context.commit(Mutations.PURGE_AUTH);
      ApiService.post("accounts/login/", credentials)
        .then(({ data }) => {
          if (process.env.VUE_APP_USE_TOKEN) {
            localStorage.setItem("jayauthToken", data.token);
          }
          this.context.commit(Mutations.SET_AUTH);
          this.context
            .dispatch(Actions.GET_PROFILE)
            .then(() => {
              let welcome = "Connexion réussie.";
              if (this.user.is_founder || this.user.is_superuser) {
                welcome +=
                  " Bienvenue sur le backoffice co-fondateurs d'AgriBEST !";
              } else {
                welcome += " Bienvenue sur AgriBEST !";
              }

              notify({
                text: welcome,
                color: "success",
                duration: 3000,
              });

              OfflineService.syncCache()
                .then(() => {
                  resolve(data);
                })
                .catch((error) => {
                  reject(error);
                });
              if (process.env.VUE_APP_USE_TOKEN) {
                this.context.dispatch(Actions.REQUEST_FIREBASE_TOKEN, {});
              }
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          const err_msg = "Impossible de se connecter";
          this.context.commit(Mutations.SET_ERROR, [
            "Une erreur est survenue lors de la connexion.",
          ]);
          if (error.response && error.response.data) {
            if (error.response.data.detail) {
              if (error.response.data.detail === "Login or password invalid.") {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + " : identifiant ou mot de passe invalide.",
                ]);
              }
              if (error.response.data.detail === "Invalid token.") {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + " : token invalide.",
                ]);
              }
            }
            if (error.response.data.login) {
              if (
                error.response.data.login.includes(
                  "This field may not be blank."
                ) ||
                error.response.data.login.includes(
                  "This field may not be null."
                )
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + " : l'email ne peut pas être vide.",
                ]);
              }
              if (
                error.response.data.login.includes("This field is required.")
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + " : l'email est requis",
                ]);
              }
            }
            if (error.response.data.password) {
              if (
                error.response.data.password.includes(
                  "This field may not be blank."
                ) ||
                error.response.data.password.includes(
                  "This field may not be null."
                )
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + " : le mot de passe ne peut pas être vide.",
                ]);
              }
              if (
                error.response.data.password.includes("This field is required.")
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + " : Ll mot de passe est requis",
                ]);
              }
            }
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.GET_PROFILE]() {
    return new Promise((resolve, reject) => {
      ApiService.get("accounts/profile")
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve(data);
        })
        .catch((error) => {
          const err_msg = "Impossible de récupérer le profil de l'utilisateur";
          notify({
            text: err_msg,
            color: "error",
          });
          reject(error);
        });
    });
  }

  @Action
  [Actions.UPDATE_PROFILE](payload) {
    return new Promise((resolve, reject) => {
      ApiService.patch("accounts/profile/", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          this.context.commit(Mutations.SET_MANDATORY_FIELD_TO_UPDATE, false);
          notify({
            text: "Le profil a bien été mis à jour",
            color: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          const err_msg = "Impossible de mettre à jour le profil";
          if (
            error.response &&
            error.response.status &&
            error.response.status === 403
          ) {
            if (
              error.response.data &&
              error.response.data.details &&
              error.response.data.details ===
                "Exploitations must have valid siret, legal status and location_point"
            ) {
              this.context.commit(Mutations.SET_ERROR, [
                err_msg +
                  " : une ou plusieurs de vos exploitations nécessitent une action.",
              ]);
              this.context.commit(
                Mutations.SET_MANDATORY_FIELD_TO_UPDATE,
                true
              );
              let list_exploit = "<ul>";
              error.response.data.exploitations.forEach((exploit) => {
                list_exploit = list_exploit + "<li>" + exploit + "</li>";
              });
              list_exploit = list_exploit + "</ul>";
              Swal.fire({
                title: "Exploitations invalides !",
                html:
                  "Veuillez vous rendre sur la page des <b> exploitations </b>, et vous assurer qu'elles ont toutes " +
                  "une <b>adresse</b>, un numéro de <b>SIRET</b> et un statut <b>juridique</b> valide pour pouvoir " +
                  "utiliser Agribest en tant qu'agiculteur à titre principal." +
                  "<br><br> Les exploitations invalides sont les suivantes<br><br>" +
                  list_exploit,
                icon: "warning",
                buttonsStyling: false,
                confirmButtonText: "Mettre mes exploitations à jour",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-primary",
                },
              }).then((result) => {
                error.response.redirect = result.isConfirmed;
                reject(error.response);
                router.push({ name: "exploitations" });
              });
            } else {
              notify({
                text: err_msg,
                color: "error",
              });
            }
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.LOGOUT](payload) {
    return new Promise((resolve, reject) => {
      if (!process.env.VUE_APP_USE_TOKEN) {
        this.context
          .dispatch(Actions.BASIC_LOGOUT, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            //Error message managed in BASIC_LOGOUT
            reject(error);
          });
      } else {
        this.context
          .dispatch(Actions.FIREBASE_LOGOUT, payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            const err_msg = "Impossible de se déconnecter de firebase";
            notify({
              text: err_msg,
              color: "error",
            });
            reject(error);
          });
      }
    });
  }

  @Action
  [Actions.FIREBASE_LOGOUT](payload) {
    return new Promise((resolve, reject) => {
      // revoke token from firebase
      window.cordova.plugins.firebase.messaging
        .deleteToken()
        .then(() => {
          const payloadDevice = {
            id: this.context.getters.deviceId(),
            active: false,
          };
          // Delete token from backend
          this.context
            .dispatch(Actions.UPDATE_DEVICE, payloadDevice)
            .then(async () => {
              try {
                const response = await manageLogout(payload, this.context);
                resolve(response);
              } catch (error) {
                reject(error);
              }
            })
            .catch(async () => {
              if (this.context.getters.deviceId() === null) {
                try {
                  const response = await manageLogout(payload, this.context);
                  resolve(response);
                } catch (error) {
                  reject(error);
                }
              } else {
                reject();
              }
            });
        })
        .catch(async () => {
          // deletion from firebase failed,
          // still disconnect the user.
          // TODO: delete firebase token from backend anyways ?
          try {
            const response = await manageLogout(payload, this.context);
            resolve(response);
          } catch (error) {
            reject(error);
          }
        });
    });
  }

  @Action
  [Actions.COOP_LOGIN](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("accounts/coop-auth/", payload)
        .then((response) => {
          this.context.commit(Mutations.SET_AUTH);
          this.context
            .dispatch(Actions.GET_PROFILE)
            .then(() => {
              let welcome = "Connexion réussie.";
              if (this.user.is_founder || this.user.is_superuser) {
                welcome +=
                  " Bienvenue sur le backoffice co-fondateurs d'AgriBEST !";
              } else {
                welcome += " Bienvenue sur AgriBEST !";
              }

              notify({
                text: welcome,
                color: "success",
                duration: 3000,
              });

              OfflineService.syncCache()
                .then(() => {
                  resolve(response);
                })
                .catch((error) => {
                  reject(error);
                });
              if (process.env.VUE_APP_USE_TOKEN) {
                this.context.dispatch(Actions.REQUEST_FIREBASE_TOKEN, {});
              }
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((e) => {
          reject(e);
        });
    });
  }

  @Action
  [Actions.BASIC_LOGOUT](payload) {
    return new Promise((resolve, reject) => {
      if (!this.online) {
        notify({
          text: "Déconnexion impossible hors-ligne !",
          color: "error",
          duration: 3000,
        });
      } else {
        ApiService.post("accounts/logout/", payload)
          .then(({ data }) => {
            //clear local storage to avoid mixing data from different users
            localStorage.clear();

            this.context.commit(Mutations.PURGE_AUTH);
            notify({
              text: "Déconnexion réussie. A bientôt sur AgriBEST !",
              color: "success",
              duration: 3000,
            });
            resolve(data);
          })
          .catch((error) => {
            const err_msg = "Impossible de se déconnecter";
            notify({
              text: err_msg,
              color: "error",
            });
            reject(error);
          });
      }
    });
  }

  @Action
  [Actions.REGISTER](credentials) {
    credentials.redirect_uri = process.env.VUE_APP_REDIRECT_URI_VERIFY;
    return new Promise((resolve, reject) => {
      ApiService.post("accounts/register/", credentials)
        .then(({ data }) => {
          notify({
            text: "L'inscription a bien été prise en compte, mais votre compte n'est pas encore activé !",
            color: "success",
            duration: 3000,
          });
          resolve(data);
        })
        .catch((error) => {
          const err_msg = "Impossible de s'inscrire";

          if (error.response && error.response.data) {
            if (error.response.data.password) {
              error.response.data.password.forEach((psw_str_err) => {
                const msg = parsePasswordStrengthError(psw_str_err);
                if (msg !== "") {
                  this.context.commit(Mutations.SET_ERROR, [msg]);
                }
              });
            } else {
              notify({
                text: err_msg + " : erreur sur le mot de passe",
                color: "error",
              });
            }
            if (error.response.data.email) {
              if (
                error.response.data.email.includes(
                  "user with this email address already exists."
                )
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg +
                    " : un utilisateur avec cette adresse email existe déjà.",
                ]);
              }
            } else {
              notify({
                text: err_msg + " : erreur sur l'email'",
                color: "error",
              });
            }
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.RESEND_VALIDATION_EMAIL](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("accounts/resend-email/", credentials)
        .then(({ data }) => {
          notify({
            text: "Email de validation du compte envoyé. Pensez à vérifier vos spams !",
            color: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          const err_msg =
            "Impossible de renvoyer le mail d'activation du compte";

          if (
            error.response &&
            error.response.data &&
            error.response.data.detail &&
            error.response.data.detail === "Not found."
          ) {
            this.context.commit(Mutations.SET_ERROR, [
              err_msg + ": le compte " + credentials.email + " n'existe pas.",
            ]);
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.VERIFY_REGISTRATION](credentials) {
    return new Promise((resolve, reject) => {
      ApiService.postWithTimeout("accounts/verify-registration/", credentials, {
        timeout: 10000,
      })
        .then(({ data }) => {
          if (process.env.VUE_APP_USE_TOKEN) {
            localStorage.setItem("jayauthToken", data.token);
          }
          this.context.commit(Mutations.SET_AUTH);
          OfflineService.syncCache()
            .then(() => {
              notify({
                text: "Le compte a bien été vérifié !",
                color: "success",
              });
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
          if (process.env.VUE_APP_USE_TOKEN) {
            this.context.dispatch(Actions.REQUEST_FIREBASE_TOKEN, {});
          }
        })
        .catch((error) => {
          const err_msg = "Impossible de vérifier le compte utilisateur";
          if (error.response && error.response.data) {
            if (error.response.data.detail) {
              if (error.response.data.detail === "User not found") {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + " : utilisateur inconnu.",
                ]);
              }
              if (error.response.data.detail === "Invalid signature") {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + ": signature invalide.",
                ]);
              }
            }
            if (error.response.data.user_id) {
              if (
                error.response.data.user_id.includes("This field is required.")
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + " : l'ID de l'utilisateur est requis.",
                ]);
              }
            }
            if (error.response.data.timestamp) {
              if (
                error.response.data.timestamp.includes(
                  "This field is required."
                )
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + " : l'horodatage est requis.",
                ]);
              }
            }
            if (error.response.data.signature) {
              if (
                error.response.data.signature.includes(
                  "This field is required."
                )
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  err_msg + " : la signature est requise.",
                ]);
              }
            }
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("accounts/send-reset-password-link/", payload)
        .then(({ data }) => {
          notify({
            text: "Le mail a bien été envoyé !",
            color: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          const err_msg =
            "Impossible d'envoyer le mail de réinitialisation du mot de passe";
          if (
            error.response &&
            error.response.data &&
            error.response.data.detail.includes("User not found")
          ) {
            this.context.commit(Mutations.SET_ERROR, [
              err_msg +
                " l'adresse mail " +
                payload.login +
                " ne correspond à aucun utilisateur connu.",
            ]);
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("accounts/reset-password/", payload)
        .then(({ data }) => {
          notify({
            text: "Réinitialisation du mot de passe réussie ! Redirection vers la page de connexion. ",
            color: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          const err_msg = "Impossible de réinitialiser le mot de passe";
          if (
            error.response &&
            error.response.data &&
            error.response.data.password
          ) {
            error.response.data.password.forEach((psw_str_err) => {
              const msg = parsePasswordStrengthError(psw_str_err);
              if (msg !== "") {
                this.context.commit(Mutations.SET_ERROR, [msg]);
              }
            });
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.CHANGE_PASSWORD](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("accounts/change-password/", payload)
        .then(({ data }) => {
          notify({
            text: "Le mot de passe a été changé avec succés.",
            color: "success",
            duration: 3000,
          });
          this.context.commit(Mutations.PURGE_AUTH);
          resolve(data);
        })
        .catch((error) => {
          const err_msg = "Impossible de modifier le mot de passe";
          if (error.response && error.response.data) {
            if (error.response.data.old_password) {
              if (
                error.response.data.old_password.includes(
                  "This field is required."
                )
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  "L'ancien mot de passe est requis.",
                ]);
              }
              if (
                error.response.data.old_password.includes(
                  "Old password is not correct"
                )
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  "L'ancien mot de passe n'est pas correct.",
                ]);
              }
            }
            if (error.response.data.password) {
              error.response.data.password.forEach((psw_str_err) => {
                const msg = parsePasswordStrengthError(psw_str_err);
                if (msg !== "") {
                  this.context.commit(Mutations.SET_ERROR, [msg]);
                }
              });
            }
            if (error.response.data.password_confirm) {
              if (
                error.response.data.password_confirm.includes(
                  "This field is required."
                )
              ) {
                this.context.commit(Mutations.SET_ERROR, [
                  "La confirmation du mot de passe est requis.",
                ]);
              }
            }
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }
          reject(error);
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH]() {
    return ApiService.get("accounts/profile")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, data);
        this.context.commit(Mutations.SET_AUTH);
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          this.context.commit(Mutations.PURGE_AUTH);
        }
      });
  }

  @Action
  [Actions.VERIFY_USER_STATUS](payload) {
    return ApiService.post("accounts/is-active/", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_USER, { is_active: data.active });
      })
      .catch((error) => {
        const err_msg = "Impossible de vérifier le statut de l'utilisateur";
        if (
          error.response &&
          error.response.data &&
          error.response.data.detail &&
          error.response.data.detail === "Not found."
        ) {
          this.context.commit(Mutations.SET_ERROR, [
            err_msg + " le compte " + payload.email + " n'existe pas.",
          ]);
        } else {
          notify({
            text: err_msg,
            color: "error",
          });
        }
      });
  }

  @Action
  [Actions.DELETE_ACCOUNT](payload) {
    payload.revoke_token = true;
    return new Promise((resolve, reject) => {
      ApiService.post("accounts/delete-account/", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.PURGE_AUTH);
          notify({
            text: "Le compte a été supprimé avec succès",
            color: "success",
          });
          resolve(data);
        })
        .catch((error) => {
          const err_msg = "Impossible de supprimer le compte utilisateur";

          if (error.response && error.response.data) {
            if (error.response.data.includes("Login or password invalid.")) {
              this.context.commit(Mutations.SET_ERROR, [
                err_msg + " : le mot de passe est incorrect.",
              ]);
            }

            if (
              error.response.data.detail &&
              error.response.data.detail === "Not found."
            ) {
              this.context.commit(Mutations.SET_ERROR, [
                err_msg + " : le compte " + payload.email + " n'existe pas.",
              ]);
            }
          } else {
            notify({
              text: err_msg,
              color: "error",
            });
          }

          reject(error);
        });
    });
  }
}
